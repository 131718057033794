import React from "react";
import { graphql, StaticQuery } from 'gatsby';
import Cookies from 'js-cookie';
import Layout from '../components/Layout';
import LogosGrid from '../components/about/LogosGrid';
import Overview from '../components/about/Overview';
import WordMarque from '../components/WordMarque';
import Footer from '../components/utils/Footer';
import Seo from '../components/Seo';

import animateCover from '../helpers/animateCover';

import '../styles/components/story.scss';
import '../styles/components/story-panel.scss';

class About extends React.Component {

  render() {
    return (
      <>
        <StaticQuery 
          query={
            graphql`
              query About {
                allWpPage(filter: {id: {eq:"cG9zdDozMjM="} }) {
                  edges {
                    node {
                      title
                      id
                      content
                      footerImage {
                        footerImageMobile {
                          localFile {
                            childImageSharp {
                              gatsbyImageData(placeholder: NONE)
                            }
                          }
                        }
                        footerImageDesktop {
                          localFile {
                            childImageSharp {
                              gatsbyImageData(placeholder: NONE)
                            }
                          }
                        }
                      }
                      logosGrid {
                        logosGrid {
                          localFile {
                            childImageSharp {
                              gatsbyImageData(placeholder: NONE)
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            `
          }
          render={ ( about ) => (
            <>
              <Layout>
                <Seo scheme={ 'scheme:highlight' } title={ about.allWpPage.edges[0].node.title } />
                <WordMarque />
                <article className={ 'about story bg:background-tint' }>
                  <div className={ 'story-panel' }>
                    <Overview title={ about.allWpPage.edges[0].node.title } content={ about.allWpPage.edges[0].node.content } />
                    <LogosGrid logos={ about.allWpPage.edges[0].node.logosGrid } />
                  </div>
                </article>
                <Footer classes={ '' } desktop={ about.allWpPage.edges[0].node.footerImage.footerImageDesktop } mobile={ about.allWpPage.edges[0].node.footerImage.footerImageMobile } />
              </Layout>
            </>
          )}
        />
      </>
    );
  }

}

export default About;