import React from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

import '../../styles/components/logos-grid-about.scss';

export default function LogosGrid( props ) {
	return (
		<article className={ 'story-section logos-grid-about text:copy' }>

			<h2 className={ 'text:sm text:sm-tracked uppercase mobile' }>Supporters</h2>

			<ul className={ 'flex spacious' }>
				{ props.logos && props.logos.logosGrid.map((logo) => (
					<li>
						<figure>
							<GatsbyImage objectFit={ 'cover' } image={ logo.localFile.childImageSharp.gatsbyImageData }  />
						</figure>
					</li>
				)) }
			</ul>

		</article>
	);
}