import React from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import '../../styles/components/credit.scss';

export default function Footer( props ) {
	return (
		<footer className={ 'credit text:rg text-centred ' + props.classes } >
			{ props.desktop && (
				<GatsbyImage 
                alt={ "Building Ways" } 
                className={ 'cover desktop' } 
                image={ props.desktop.localFile.childImageSharp.gatsbyImageData } />
			)}
			{ props.mobile && (
				<GatsbyImage 
                alt={ "Building Ways" } 
                className={ 'cover mobile' } 
                image={ props.mobile.localFile.childImageSharp.gatsbyImageData } />
			)}
			<p className={ 'credit-main text:sm uppercase' }><a className={ 'flex spacious' } rel="nofollow" target="_blank" href="https://mooradian.co.uk">Project by Mooradian Studio</a></p>
			<p className={ 'flex spacious credit-line text:sm uppercase' }><span>&copy; 2024</span> <span><a rel="nofollow" target="_blank" href="https://polytechnic.works">Credit</a></span></p>
		</footer>
	);
}